import type { BreadcrumbList, ListItem, WithContext } from 'schema-dts'
import Head from 'next/head'
import config from '../../../config'

export interface ISEOBreadcrumb {
  elementList: { title: string; link?: string }[]
  category?: string
  url?: string
  title?: string
}

const getDefaultBreadCrumb = (
  url: string,
  title: string,
  category?: string,
): WithContext<BreadcrumbList> => {
  const categoryItem: ListItem = {
    '@type': 'ListItem',
    position: 2,
    name: category,
    item: `${config.domain}/${url?.split('/')[1]}/`,
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Accueil',
        item: config.domain,
      },
      ...(category ? [categoryItem] : []),
      {
        '@type': 'ListItem',
        position: category ? 3 : 2,
        name: title,
      },
    ],
  }
}

export function SEOBreadcrumb({ elementList, category, url, title }: ISEOBreadcrumb): JSX.Element {
  const breadcrumbSEOBase = elementList?.length
    ? {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          ...elementList.map(({ title, link }, index) => {
            const listItem: ListItem = {
              '@type': 'ListItem',
              name: title,
              position: index + 1,
            }
            if (index !== elementList.length - 1) {
              const url = `${config.domain}${link}`
              listItem.item = index ? `${url.endsWith('/') ? url : `${url}/`}` : config.domain
            }
            return listItem
          }),
        ],
      }
    : getDefaultBreadCrumb(url, title, category)

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            breadcrumbSEOBase,
            null,
            config.environment === 'production' ? null : 4,
          ),
        }}
      />
    </Head>
  )
}
